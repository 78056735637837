const websocketUrl = process.env.VUE_APP_WEB_SOCKET_URL;

class WebSocketService {
	constructor() {
		this.socket = null;
		this.messageCallback = null;
		this.reconnectDelay = 2000; // Start with 2 seconds
		this.maxReconnectDelay = 30000; // Max delay of 30 seconds
		this.pingInterval = null; // For keeping the connection alive
		this.connect();
	}

	connect() {
		console.log('Connecting to WebSocket...');
		this.socket = new WebSocket(websocketUrl);

		this.socket.onopen = this.onOpen.bind(this);
		this.socket.onmessage = this.onMessage.bind(this);
		this.socket.onclose = this.onClose.bind(this);
		this.socket.onerror = this.onError.bind(this);
	}

	onOpen(event) {
		console.log('WebSocket connection opened:', event);

		// Reset reconnect delay on successful connection
		this.reconnectDelay = 2000;

		// Send keep-alive pings every 25 seconds
		this.pingInterval = setInterval(() => {
			if (this.socket.readyState === WebSocket.OPEN) {
				this.socket.send(JSON.stringify({ type: 'ping' }));
			}
		}, 25000);
	}

	onMessage(event) {
		let message;
		if (event.data instanceof Blob) {
			const reader = new FileReader();
			reader.onload = () => {
				message = reader.result;
				this.processMessage(message);
			};
			reader.readAsText(event.data);
		} else {
			message = event.data;
			this.processMessage(message);
		}
	}

	processMessage(message) {
		try {
			const parsedMessage = JSON.parse(message);
			if (this.messageCallback) {
				this.messageCallback(parsedMessage);
			}
		} catch (error) {
			console.error('Error parsing WebSocket message:', error);
		}
	}

	onClose(event) {
		console.warn(`WebSocket closed. Code: ${event.code}, Reason: ${event.reason}`);

		// Clear keep-alive pings
		if (this.pingInterval) {
			clearInterval(this.pingInterval);
			this.pingInterval = null;
		}

		// Attempt reconnection with exponential backoff
		setTimeout(() => {
			this.reconnectDelay = Math.min(this.reconnectDelay * 2, this.maxReconnectDelay);
			this.connect();
		}, this.reconnectDelay);
	}

	onError(event) {
		console.error('WebSocket error:', event);
		this.socket.close(); // Force reconnect on error
	}

	send(message) {
		if (this.socket.readyState === WebSocket.OPEN) {
			this.socket.send(JSON.stringify(message));
		} else {
			console.warn('WebSocket is not open. Message not sent:', message);
		}
	}

	close() {
		if (this.socket) {
			this.socket.close();
		}
	}

	setMessageCallback(callback) {
		this.messageCallback = callback;
	}
}

export default WebSocketService;
