<template>
  <div class="">
    <!-- neet to add this class "chat-module-left" -->
    <div class="position-relative">
      <div class="open-chat-msg">
        <div class="d-flex align-items-center">
          <!-- Fade in the chat button -->
          <div
            v-if="!isChatModelShow && isVisible"
            class="chat-button cursor-pointer position-relative"
            @click="openChatBox"
          >
            <p class="mb-0 d-flex align-items-center">
              <img
                src="/images/chat-icon.svg"
                class="chat-icon-white"
				alt="chat"
              >
              <img
                src="/images/golden-chat-icon.svg"
                class="chat-icon-golden"
				alt="chat"
              >
            </p>

            <transition 
              name="fade"
              @after-enter="handleFadeStart"
            >
              <div 
                v-if="isShowLeftNotiCnt"
                class="animat-cell-left"
              >
                <div
                  v-if="!isChatModelShow && isVisible"
                  class="count-circle-left"
                  :class="msgNotificationClass"
                >
                  <span
                    v-if="totalMessageCount > 0"
                    class="msg-notification"
                  >
                    {{ totalMessageCount }}
                  </span>
                </div>
              </div>
            </transition>
          </div>
          <!-- :class="{'rotate-in-down-left':!this.isChatModelShow && isVisible, 'rotate-in-up-left':!this.isChatModelShow && !isVisible}" -->
						
          <!-- <div class="animat-cell-left">
								<div class="count-circle-left rotate-in-up-left">
									<span class="msg-notification">1</span>
								</div>
							</div> -->

          <!-- Fade in the left-right arrow and the notification count -->
          <div class="left-right-arrow d-flex align-items-center ml-10 ">
            <!-- <transition name="fade" @after-enter="handleFadeComplete">
							<span class="msg-notification" v-if="!this.isChatModelShow && !isVisible && hasMoved">1</span>
						</transition> -->
            <transition
              name="fade"
              @after-enter="handleFadeComplete"
            >
              <div
                v-if="isShowRightNotiCnt"
                class="animat-cell"
              >
                <div
                  v-if="!isChatModelShow && !isVisible"
                  class="count-circle "
                  :class="msgNotificationRevertClass"
                >
                  <span
                    v-if="totalMessageCount > 0"
                    class="msg-notification"
                  >
                    {{ totalMessageCount }}
                  </span>
                </div>
              </div>
            </transition>
            <img
              v-show="!isChatModelShow && isVisible"
              src="/images/right-side-arrow.svg"
              @click="toggleDiv"
              alt="Hide"
            >
            <img
              v-show="!isChatModelShow && !isVisible"
              src="/images/left-side-arrow.svg"
              @click="toggleDiv"
              alt="show"
            >
          </div>
        </div>
        <div
          v-if="isChatModelShow"
          class="minimize-button cursor-pointer"
          @click="closeChatBox"
        >
          <img src="/images/chat-bottom-arrow.svg" alt="close">
        </div>

        <div
          v-if="isChatModelShow"
          class="chatbox-popup-box"
        >
          <div
            v-if="!isShowChatScreen && !isShowNewMsgScreen"
            class="top-grid-header d-flex justify-content-between"
          >
            <p class="chat-title mb-0">
              Chats
            </p>
            <div
              class="edit-icon-msgr"
              @click="openNewMsgScreen"
            >
              <Popper
                :placement="'top'"
                :content="newmessagetooltips"
                hover
                arrow
              >
                <img
                  src="/images/chat-icon-black.svg"
                  class="cursor-pointer"
                  alt="open"
                >
              </Popper>
            </div>
          </div>
          <div
            v-else-if="isShowNewMsgScreen"
            class="top-grid-header admin-chat-header d-flex align-items-center"
          >
            <img
              src="/images/left-arrow-icon-01.svg"
              class="cursor-pointer backside-arrow"
              @click="doChatNewMsgClose()"
              alt="close"
            >
          </div>
					
          <div
            v-else
            class="top-grid-header admin-chat-header d-flex align-items-center justify-content-between"
          >
            <img
              src="/images/left-arrow-icon-01.svg"
              class="cursor-pointer backside-arrow"
              @click="doChatMsgClose()"
              alt="close"
            >
            <p
              v-if="currentUserName != ''"
              class="chat-title mb-0 text-center w-100"
            >
              {{ currentUserName }}
            </p>
            <p
              v-if="!isEditing && currentGroupName != ''"
              class="currentGroupName-edit mb-0 text-center w-100"
            >
              {{ currentGroupName }}
            </p>
            <input
              v-if="isEditing"
              ref="groupName"
              v-model="currentGroupName"
              type="text"
              class="updateGroupName-inpt text-center w-100"
              @blur="updateGroupName"
              @input="handleInputChange"
            >
            <div
              v-if="currentGroupIdVal"
              class="d-flex grp-name-edit-icon"
            >
              <img
                src="/images/group-edit-icon.svg"
                class="cursor-pointer mr-30"
                @click="focusOnEdit"
				alt="edit"
              >
              <img
                src="/images/multi-user-icon.svg"
                class="cursor-pointer"
                @click="openNewMsgScreen"
				alt="open"
              >
            </div>
          </div>
          <div
            v-if="!isShowChatScreen && !isShowNewMsgScreen"
            class="chat-support-list"
          >
            <div class="chattingList-user cursor-pointer d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <div class="usr-profile-photo position-relative">
                  <img
                    src="/images/round-img-01.svg"
                    class="user profile"
					alt="user profile"
                  >
                </div>
                <div class="user-name-group">
                  <p class="profile-name">
                    Sentient AI
                  </p>
                  <p class="profile-tagline mb-0">
                    The weather
                  </p>
                </div>
              </div>
              <div class="">
                <p class="msg-notify-time mb-0">
                  1d
                </p>
              </div>
            </div>

            <div
              v-for="recentUser in recentMsgUsersData"
              :key="recentUser.id"
              class="chattingList-user cursor-pointer d-flex justify-content-between align-items-center"
              :class="{ 'active-notify': recentUser.notification_count > 0 }"
              @click="doChatMsgOpen(recentUser)"
            >
              <div
                v-if="recentUser.group_id == null"
                class="d-flex align-items-center"
              >
                <div
                  v-if="recentUser.group_id != ''"
                  class="usr-profile-photo position-relative"
                >
                  <img
                    v-if="recentUser.role_name=='Doctor'"
                    src="/images/user-profile-02.svg"
					alt="doctor"
                  >
                  <img
                    v-else
                    src="/images/user-profile-01.svg"
					alt="user"
                  >
                  <span
                    v-if="recentUser.notification_count > 0"
                    class="msg-notification-02"
                  >{{ recentUser.notification_count }}</span>
                </div>
                <div class="user-name-group">
                  <p class="profile-name">
                    {{ recentUser.user_name }}
                  </p>
                  <p class="profile-tagline mb-0 recent-coming-msg">
                    {{ recentUser.message }}
                  </p>
                </div>
              </div>
              <div
                v-else
                class="d-flex align-items-center"
              >
                <div class="usr-profile-photo position-relative">
                  <img src="/images/user-group-chat.svg" alt="group chat">
                  <span
                    v-if="recentUser.notification_count > 0"
                    class="msg-notification-02"
                  >{{ recentUser.notification_count }}</span>
                </div>
                <div class="user-name-group">
                  <p class="profile-name">
                    {{ recentUser.group_name }}
                  </p>
                  <p class="profile-tagline mb-0 recent-coming-msg">
                    {{ recentUser.message }}
                  </p>
                </div>
              </div>
              <div class="">
                <p class="msg-notify-time mb-0">
                  {{ recentUser.last_update }}
                </p>
              </div>
            </div>
          </div>
          <slot>
            <div
              v-if="isShowChatScreen || isShowNewMsgScreen"
              class="in-out-msg-list"
            >
              <div class="d-flex flex-column justify-content-between overflow-hidden h-100">
                <div
                  v-if="isShowChatScreen"
                  ref="msgScreen"
                  class="personal-msg-screen"
                >
                  <div
                    v-for="(message, index) in messages"
                    :key="message.id"
                    ref="lastMessage"
                  >
                    <p class="receive-time text-center">
                      {{ index }}
                    </p>
                    <div
                      v-for="msg in message"
                      :key="msg.id"
                    >
                      <div
                        v-if="msg.isDivider && messageCount > 0"
                        ref="unreadMessageDiv"
                        class="unread-messages-div unread-msg-line text-center position-relative mt-30 mb-30"
                      >
                        <p class="unread-tagline mb-0">
                          {{ messageCount }} UNREAD MESSAGES
                        </p>
                      </div>

                      <div v-if="currentGroupIdVal == null && msg.message != ''">
                        <div
                          v-if="msg.sender_user_id == currentUserIdVal"
                          class="d-inline-block w-100"
                        >
                          <div class="incoming-chat position-relative">
                            <pre class="mb-0 fs-16 message-font">{{ msg.message }}</pre>
                            <span class="in-msg-time-format">{{ msg.sent_time }}</span>
                          </div>
                        </div>
                        <div
                          v-else
                          class="personalChat-msg overflow-hidden"
                        >
                          <div class="incoming-chat position-relative">
                            <pre class="mb-0 fs-16 message-font">{{ msg.message }}</pre>
                            <span class="msg-time-format">{{ msg.sent_time }}</span>
                          </div>
                        </div>
                      </div>

                      <div v-if="currentGroupIdVal != null && msg.message != ''">
                        <div
                          v-if="msg.sender_user_id != currentUserIdVal"
                          class="d-inline-block w-100"
                        >
                          <div class="incoming-chat position-relative">
                            <p
                              class="grp-chat-name"
                              :style="{ color: msg.color_code }"
                            >
                              {{ msg.sender_name }}
                            </p>
                            <pre class="mb-0 fs-18 message-font">{{ msg.message }}</pre>
                            <span class="in-msg-time-format">{{ msg.sent_time }}</span>
                          </div>
                        </div>
                        <div
                          v-else
                          class="personalChat-msg overflow-hidden"
                        >
                          <div class="incoming-chat position-relative">
                            <pre class="mb-0 fs-16 message-font">{{ msg.message }}</pre>
                            <span class="msg-time-format">{{ msg.sent_time }}</span>
                          </div>
                        </div>
                      </div>
									
                      <div
                        v-if="msg.sender_user_id == currentUserIdVal && msg.document_path != null"
                        class="d-inline-block w-100"
                      >
                        <!-- <p class="incoming-chat">{{ message.message }}</p> -->
                        <slot v-if="msg.document_path != ''">
                          <div
                            v-for="(doc, docIndex) in msg.document_path"
                            :key="docIndex"
                            class="d-flex align-items-center "
                            :class="{ 'justify-content-end': msg.isSent, '': !msg.isSent }"
                          >
                            <span
                              v-if="doc.file_type == 'application/pdf'"
                              :href="documentUrl(doc.path)"
                              target="_blank"
                              class="upload-file-group text-decoration-none  position-relative"
                            >
                              <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                  <em class="fa-regular fa-file-lines docs-file-icon"></em>
                                  <div class="ml-15 mr-15">
                                    <span class="docName">{{ extractFileName(doc.path) }}</span>
                                    <p class="docs-size-b mb-0">{{ doc.file_size }}</p>
                                  </div>
                                </div>
                                <span @click="downloadFile(doc.path)"><em class="fa-solid fa-download file-download-icon cursor-pointer"></em></span>
                              </div>
                              <span class="in-msg-time-format">{{ msg.sent_time }}</span>
                            </span>
                            <span v-else>
                              <div class="image-preview-box mb-10 position-relative">
                                <a
                                  :href="documentUrl(doc.path)"
                                  target="_blank"
                                  class="cursor-pointer"
                                >
                                  <img
                                    :src="documentUrl(doc.path)"
                                    alt="Image preview"
                                  >
                                </a>
                                <span class="in-msg-time-format">{{ msg.sent_time }}</span>
                              </div>
                            </span>
                          </div>
                        </slot>
                      </div>
                      <div
                        v-else
                        class="personalChat-msg overflow-hidden"
                      >
                        <slot v-if="msg.document_path != ''">
                          <div
                            v-for="(doc, docPathIndex) in msg.document_path"
                            :key="docPathIndex"
                            class="d-flex align-items-center"
                            :class="{ 'justify-content-end': msg.isSent, '': !msg.isSent }"
                          >
                            <div>
                              <span
                                v-if="doc.file_type == 'application/pdf'"
                                :href="documentUrl(doc.path)"
                                target="_blank"
                                class="upload-file-group text-decoration-none d-flex align-items-center justify-content-between position-relative"
                              >
                                <div class="d-flex align-items-center">
                                  <em class="fa-regular fa-file-lines docs-file-icon"></em>
                                  <div class="ml-15 mr-15">
                                    <span class="docName">{{ extractFileName(doc.path) }}</span>
                                    <p class="docs-size-b mb-0">{{ doc.file_size }}</p>
                                  </div>
                                </div>
                                <span @click="downloadFile(doc.path)">
									<em class="fa-solid fa-download file-download-icon cursor-pointer"></em>
                                </span>
                                <span class="in-msg-time-format">{{ msg.sent_time }}</span>
                              </span>
                              <span v-else>
                                <div class="image-preview-box mb-10 position-relative">
                                  <a
                                    :href="documentUrl(doc.path)"
                                    target="_blank"
                                    class="cursor-pointer"
                                  >
                                    <img
                                      :src="documentUrl(doc.path)"
                                      alt="Image preview"
                                    >
                                  </a>
                                  <span class="in-msg-time-format">{{ msg.sent_time }}</span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </slot>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="isShowNewMsgScreen"
                  class="search-to-admin-user"
                >
                  <div
                    class="user-find-aria d-flex align-items-center"
                    style="flex-wrap: wrap;"
                  >
                    <p class="mb-0 to-msg fw-700">
                      To:
                    </p>
                    <div
                      class="select-muliName-input position-relative"
                      style="display: flex; flex-direction: column; height: 100%; overflow-y: auto;"
                    >
                      <div
                        class="multi-user-div d-flex align-items-center flex-wrap"
                        style="flex-grow: 1; gap: 5px;"
                      >
                        <span
                          v-for="user in selectedUsers"
                          :key="user.id"
                          class="selected-multi-user white-space-nowrap d-flex align-items-center"
                        >
                          {{ user.full_name }}
                          <img
                            src="/images/close-icon.svg"
                            class="cursor-pointer ml-10"
                            style="height: calc(var(--scale-ratio) * 10px);"
                            @click="deselectUser(user)"
							alt="close"
                          >
                        </span>
                        <input
                          v-model="searchQuery"
                          type="text"
                          class="form-control msger-input search-inp-box"
                          placeholder="Search User..."
                          style="margin-top: auto;width: calc(var(--scale-ratio) * 150px);"
                          @input="getUsers"
                        >
                      </div>
                    </div>
                    <img
                      src="/images/right-arrow-icon.svg"
                      class="cursor-pointer ml-10"
                      style="width: calc(var(--scale-ratio) * 50px);"
                      @click="activeSearchedUser()"
                      alt="search"
                    >
                  </div>
                  <ul
                    v-for="user in searchUsers"
                    :key="user.id"
                    class="search-list p-0 m-0"
                  >
                    <li
                      class="cursor-pointer"
                      @click="selectSearchedUsers(user)"
                    >
                      <img
                        v-if="user.role != 'Doctor'"
                        src="/images/user-profile-01.svg"
						alt="user profile"
                      >
                      <img
                        v-else
                        src="/images/user-profile-02.svg"
						alt="user profile"
                      >
                      <span>{{ user.full_name }}</span>
                    </li>
                  </ul>
                </div> 
              </div>
            </div>
            <div
              v-if="isShowChatScreen"
              class="msger-inputarea"
            >
              <div v-if="file.length > 0">
                <div class="document-upload-area d-flex justify-content-center align-items-center">
                  <div
                    v-for="(fileDetail, index) in file"
                    :key="fileDetail.name"
                  >
                    <div
                      v-if="fileDetail.type == 'application/pdf'"
                      class="upload-pdf-docs cursor-pointer position-relative d-flex align-items-center justify-content-center"
                    >
					<em class="fa-solid fa-file-pdf"></em>
                      <em
                        class="fa-solid fa-xmark remove-file"
                        @click="removeFile(index)"
                      />
                    </div>
                    <div
                      v-if="fileDetail.type.startsWith('image/')"
                      class="upload-pdf-docs cursor-pointer position-relative d-flex align-items-center justify-content-center"
                    >
                    <em class="fa-solid fa-file-image"></em>
                      <em
                        class="fa-solid fa-xmark remove-file"
                        @click="removeFile(index)"
                      ></em>
                    </div>
                    <!-- <div v-if="file.type.startsWith('image/')" class="upload-image-docs cursor-pointer position-relative d-flex align-items-center justify-content-center">
											<img :src="getObjectURL(file)" alt="Image preview" class="image-preview" style="height:calc(var(--scale-ratio) * 52px); width: calc(var(--scale-ratio) * 45px); position: relative;">
											<i class="fa-solid fa-xmark remove-file" @click="removeFile(index)" style="position: absolute; top: calc(var(--scale-ratio) * -10px); right: calc(var(--scale-ratio) * -10px); background-color: white; border-radius: 50%; padding: calc(var(--scale-ratio) * 5px); font-size: calc(var(--scale-ratio) * 14px);"></i>
										</div> -->
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <img
                  src="/images/attach-file.svg"
                  class="attach-file-icon"
                  @click="triggerFileInput"
                  alt="attach"
                >
                <div class="newMessage-type-area w-100">
                  <input
                    id="file"
                    ref="fileInput"
                    type="file"
                    accept=".pdf, .jpg, .jpeg, .png"
                    style="display: none;"
                    multiple
                    @change="uploadDocument"
                  >
                  <!-- <input type="text" class="form-control msger-input cursor-auto" v-model="newMessage" @keyup.enter="sendMessage" placeholder="Type a message..."> -->
                  <textarea
                    v-model="newMessage"
                    type="text"
                    class="form-control msger-input cursor-auto"
                    placeholder="Type a message..."
                    @keydown="handleKeyDown"
                    @focus="isChatInputFocus = true"
                    @blur="isChatInputFocus = false"
                  />
                </div>
                <div
                  class="cursor-pointer"
                  @click="handleArrowClick"
                >
                  <img
                    :src="newMessage != '' || file.length != 0 ? '/images/dark-sent-msg-icon.svg' : '/images/sent-msg-icon.svg'"
                    alt="Send Message Icon"
                    class="newMessage-send-icon"
                  >
                </div>
              </div>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Popper from "vue3-popper";
import axios from "@/scripts/axios.js";
import $ from "jquery";
import { encryptMessage, decryptMessage } from '@/cryptoUtils';
import { useApiErrorHandler } from "@/composables/useApiErrorHandler";

export default {
	components: {
		Popper,
	},
	setup: () => {
		const { handleApiError } = useApiErrorHandler();
		return { handleApiError };
	},
	data() {
		return {
			isChatModelShow:false,
			isShowChatScreen:false,
			newmessagetooltips:"New Message",
			isShowNewMsgScreen:false,
			recentMsgUsersData:[],
			isVisible: true,
			hasMoved: false,  // Tracks whether the number has moved to the second element
			msgNotificationClass:'',
			msgNotificationRevertClass:'',
			isShowLeftNotiCnt:true,
			isShowRightNotiCnt:true,
			currentUserName:'',
			currentGroupName: '',
			chatType:'user',
			messages:[],
			currentUserIdVal: null,
			currentGroupIdVal: null,
			file:[],
			searchQuery: "",
			searchUsers: [],
			selectedUsers: [],
			isEditing: false,
			typingTimeout: null,
			newMessage: '',
			totalMessageCount: 0,
			isChatInputFocus:false,
			updateGroupMembers: false,
			currentUsersGroups: [],
			senderColors: {},
			unreadDividerShown: 0,
			messageCount: 0,
		}
	},
	computed:{
      isLogin() {
        return localStorage.getItem("authToken") ? true : false;
      },
      socketData() {
        return this.$store.getters.getSocketData;
      },
    },
	watch: {
		socketData: {
			handler(newData) {
				if (newData) {
					this.handleReceivedData(newData);
				}
			},
			immediate: true,
		},
		messages: {
			handler() {
				this.scrollToLastMessage();
			},
			deep: true,
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.triggerMove(); // Start the transition when the component is mounted
			this.scrollToLastMessage();
		});
		this.getTimeZone();
		this.getTotalMessageCount();
	},
	created(){
		this.closeChatBox()
	},
	methods: {
		openChatBox(){
			this.getRecentMsgUsers()
			this.isChatModelShow = true
		},
		closeChatBox(){
			this.isShowNewMsgScreen = false
			this.isShowChatScreen =false
			this.isChatModelShow = false
			this.getTotalMessageCount();
		},
		doChatMsgOpen(recentUser) {
			this.messages = [];
			this.messageCount = recentUser.notification_count;
			this.currentGroupName = '';
			this.currentUserName = '';
			this.isEditing = false;
			this.currentUserIdVal = '';
			this.currentGroupIdVal = null;
			this.chatType = 'user';
			this.file = [];
			this.newMessage = null;
			if(recentUser.group_id != null) {
				this.currentGroupName = recentUser.group_name;
				this.fetchGroupMessages(recentUser.receiver_user_id, recentUser.group_id);
				this.currentGroupIdVal = recentUser.group_id;
				this.currentUserIdVal=recentUser.current_user_id;
				this.chatType = 'group';
				this.$nextTick(() => {
					this.scrollToLastMessage();
				});
			} else {
				this.currentUserIdVal=recentUser.receiver_user_id;
				this.fetchMessages(recentUser.receiver_user_id);
				this.currentUserName = recentUser.user_name;
				this.chatType = 'user';
				this.$nextTick(() => {
					this.scrollToLastMessage();
				});
			}
			this.isShowChatScreen = true;
		},
		doChatMsgClose(){
			this.currentGroupIdVal = null;
			this.currentUserIdVal = '';
			this.getRecentMsgUsers()
			this.isShowChatScreen = false
			this.isShowNewMsgScreen = false
			this.chatMessageUser = null
			// this.senderColors = {}
			this.getTotalMessageCount();
		},
		openNewMsgScreen(){
			this.updateGroupMembers = false;
			this.selectedUsers = [];
			this.searchUsers = [];

			const headers = {
				'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
				'Content-Type': 'application/json',
			};

			if(this.currentGroupIdVal != null) {
				this.updateGroupMembers = true;
				axios.post("chat/group/retrieve", 
					{ group_id: this.currentGroupIdVal },
					{ headers: headers }
				).then((response) => {
					if (response.data.status === 200) {					
						this.selectedUsers = Object.values(response.data.data.members_data);
					}
				}).catch((error) => {
					console.error("Error fetching messages:", error);
				});
			}

			this.isShowChatScreen = false
			this.isShowNewMsgScreen = true
		},
		doChatNewMsgClose(){
			this.currentGroupIdVal = null;
			this.currentUserIdVal = null;
			this.searchQuery = null;
			this.recentMsgUsersData = [];
			this.getRecentMsgUsers();
			this.isShowNewMsgScreen = false;
			this.isShowChatScreen = false;
			// this.senderColors = {};
			this.getTotalMessageCount();	
		},
		setUnreadDividerShown() {
			this.unreadDividerShown++;
		},
		getObjectURL(file) {
			if (file && file.type.startsWith('image/')) {
				return URL.createObjectURL(file);
			}
			return '';
		},
		documentUrl(documentPath) {
			return `${process.env.VUE_APP_STORAGE_URL}/chat/document/view/${documentPath}`;
        },
		decryptMessage(message) {
			return decryptMessage(message);
		},
		extractFileName(filePath) {
			return filePath.substring(filePath.lastIndexOf('/') + 1);
		},
		removeFile(index) {
			this.file.splice(index, 1);
		},
		fetchMessages(receiverUserId) {
			if (!receiverUserId) {
				return false;
			}

			const headers = {
				'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
				'Content-Type': 'application/json',
			};

			axios.post("chat/get-message", 
				{
				receiver_user_id: receiverUserId,
				chatType: this.chatType
				},
				{ headers: headers }
			).then((response) => {
				if (response.data.status === 200) {	
					this.messages = {};
					let previousDateKey = null;
					let dividerAdded = false;

					Object.keys(response.data.data).forEach((dateKey) => {
						this.messages[dateKey] = [];
						
						response.data.data[dateKey].forEach((detail) => {
							detail.message = decryptMessage(detail.message);
							this.messages[dateKey].push(detail);
						});

						if (dateKey === 'Today' && !dividerAdded) {
							const allSent = this.messages[dateKey].every(msg => msg.status === 'Sent');
							const isOnlyToday = Object.keys(this.messages).length === 1;
							
							const lastReadIndex = this.messages[dateKey]
								.map(msg => msg.status === 'Received')
								.lastIndexOf(true);

							if (allSent && previousDateKey && !isOnlyToday) {
								this.messages[previousDateKey].push({ isDivider: true, message: '', from: 'all_sent' });
							} else {
								if (lastReadIndex !== -1 && lastReadIndex < this.messages[dateKey].length - 1) {
									this.messages[dateKey].splice(lastReadIndex + 1, 0, { isDivider: true, message: '', from: 'last_index_but_not_read' });
								}
								dividerAdded = true;
							}
						}

						previousDateKey = dateKey;
					});
				}
			}).catch((error) => {
				console.error("Error fetching messages:", error);
			});
		},
		fetchGroupMessages(receiverUserId, groupId) {
			if (!groupId) {
				return false;
			}

			const headers = {
				'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
				'Content-Type': 'application/json',
			};

			axios.post("chat/get-message", 
				{
				receiver_user_id: receiverUserId,
				chatType: 'group',
				group_id: groupId
				},
				{ headers: headers } 
			).then((response) => {
				if (response.data.status === 200) {					
					this.messages = {};
					let previousDateKey = null;
					let dividerAdded = false;

					Object.keys(response.data.data).forEach((dateKey) => {
						this.messages[dateKey] = [];
						
						response.data.data[dateKey].forEach((detail) => {
							detail.message = decryptMessage(detail.message);
							this.messages[dateKey].push(detail);

							// Assign color_code based on sender_user_id
							if (!this.senderColors[detail.sender_user_id]) {
								this.senderColors[detail.sender_user_id] = this.getRandomColorCode();
							}
							detail.color_code = this.senderColors[detail.sender_user_id];

						});

						if (dateKey === 'Today' && !dividerAdded) {
							const allSent = this.messages[dateKey].every(msg => msg.status === 'Sent');
							const isOnlyToday = Object.keys(this.messages).length === 1;
							
							const lastReadIndex = this.messages[dateKey]
								.map(msg => msg.status === 'Received')
								.lastIndexOf(true);

							if (allSent && previousDateKey && !isOnlyToday) {
								this.messages[previousDateKey].push({ isDivider: true, message: '', from: 'all_sent' });
							} else {
								if (lastReadIndex !== -1 && lastReadIndex < this.messages[dateKey].length - 1) {
									this.messages[dateKey].splice(lastReadIndex + 1, 0, { isDivider: true, message: '', from: 'last_index_but_not_read' });
								}
								dividerAdded = true;
							}
						}

						previousDateKey = dateKey;
					});
				}
			}).catch((error) => {
				console.error("Error fetching messages:", error);
			});
		},
		handleKeyDown(event) {
			if (event.key === 'Enter') {
				if (event.shiftKey) {
					return;
				} else {
					event.preventDefault();

					if (event.key == 'Enter'|| event.type == 'click') {
						this.sendMessage(event);
					}
				}
			}
		},
		handleArrowClick(event) {
			this.$nextTick(() => {
				this.sendMessage(event);
			});
		},
		async sendMessage(event) {
			event.preventDefault();

			if (!this.newMessage && this.file.length === 0) return; // Early exit

			this.newMessage = this.file.length > 0 && !this.newMessage ? null : this.newMessage.trim();
			const encMsg = encryptMessage(this.newMessage);
			this.newMessage = null;

			const config = {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
					'Content-Type': 'multipart/form-data',
				},
			};

			const formData = new FormData();
			formData.append('message', encMsg);
			this.file.forEach((file, index) => {
				formData.append(`file${index}`, file);
			});
			if (this.currentGroupIdVal != null) {
				formData.append('group_id', this.currentGroupIdVal);
				formData.append('receiver_user_id', 0);
			} else {
				formData.append('receiver_user_id', this.currentUserIdVal);
			}

			const response = await axios.post("chat/send-message", formData, config);
			this.handleSendMsgResponse(response);

			this.afterSendMsgProcess();

		},
		handleSendMsgResponse(response){
			if (response.data.status === 200) {
				this.handleSuccessResponse(response.data.data);
			} else if (response.data.status === 422) {
				this.handleValidationError(response.data.data);
			} else if(response.status === 402){
				window.open(response.data.redirect_url, '_blank');
			} else {
				this.$filters.moshaToast(response.data.message, "error");
			}
		},
		handleSuccessResponse(detail){
			detail.type = 'message';
			this.sendMsgToSocket(detail);

			detail.type = 'notification';
			this.sendMsgToSocket(detail);
			detail.message = decryptMessage(detail.message);
			this.updateMessage(detail);
			// this.updateStatus(detail.id);

			this.newMessage = null;
			this.file = [];
			this.$refs.fileInput.value = ''; 
		},
		handleValidationError(errorData) {
			for (const key in errorData) {
				if (Object.prototype.hasOwnProperty.call(errorData, key)) {
					const errors = errorData[key];
					errors.forEach((error) => {
						this.$filters.moshaToast(error, "error");
					});
				}
			}
		},
		afterSendMsgProcess(){
			this.newMessage = null;
			this.file = [];
			const div = document.getElementsByClassName("unread-messages-div")[0];
			if (div) {
				div.remove();
			}
			this.scrollToLastMessage();
		},
		editGroupName() {
			this.isEditing = true;
		},
		handleInputChange() {
			// clearTimeout(this.typingTimeout);
			// this.typingTimeout = setTimeout(this.updateGroupName, 1000);
		},
		getRandomColorCode() {
			let r = 0, g = 0, b = 0;
			do {
				r = this.randNumber(0, 128);
				g = this.randNumber(0, 128);
				b = this.randNumber(0, 128);
			} while (Math.abs(r - g) < 30 || Math.abs(g - b) < 30 || Math.abs(r - b) < 30);

			return `rgb(${r}, ${g}, ${b})`;
		},
		randNumber(min, max) {
			return Math.floor(Math.random() * (max - min + 1)) + min;
		},
		updateGroupName() {
			if (this.currentGroupName.length > 27) {
				this.currentGroupName = this.currentGroupName.substring(0, 27) + '...';
			}

			const headers = {
			'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
			'Content-Type': 'application/json',
			};
			axios.post("chat/group/update-group-name", { id: this.currentGroupIdVal, name: this.currentGroupName }, headers).then(() => {
				this.sendMsgToSocket({'type' : 'update_group_name'});
				this.$filters.moshaToast("Group name has been updated successfully.", "success");
			}).catch((error) => this.handleApiError(error));
			this.isEditing = false;
		},
		updateMessage(newMsg) {
			if (!('Today' in this.messages)) {
				this.messages['Today'] = [];
			}
			this.messages['Today'].push(newMsg);
		},
		scrollToLastMessage() {
			this.$nextTick(() => {
				const lastMessage = this.$refs.lastMessage;
				if (lastMessage && Array.isArray(lastMessage)) {
					const lastElement = lastMessage[lastMessage.length - 1];
					if (lastElement) {
						lastElement.scrollIntoView({ behavior: 'auto', block: 'end' });
					}
				}
			});
		},
		showNotification(title, message, options = {}) {
			if (!('Notification' in window)) {
				console.error('This browser does not support notifications.');
				return;
			}

			const currentBaseURL = window.location.origin;

			const notificationChannel = new BroadcastChannel('notification_channel');

			let shouldShowNotification = true;

			notificationChannel.onmessage = (event) => {
				if (event.data.type === 'NOTIFICATION_SHOWN' && event.data.baseURL === currentBaseURL) {
					shouldShowNotification = false;
				}
			};

			setTimeout(() => {
				if (shouldShowNotification) {
					if (Notification.permission === 'granted' && this.$route.name === "Calendar") {
						new Notification(title, { ...options, body: message });
						notificationChannel.postMessage({ type: 'NOTIFICATION_SHOWN', baseURL: currentBaseURL });
					} else if (Notification.permission !== 'denied') {
						Notification.requestPermission().then(permission => {
							if (permission === 'granted' && this.$route.name === "Calendar") {
								const notification = new Notification(title, { body: message });
								notification.onclick = () => {
									console.log("at here to click on notification");
									window.focus(); 
								};
								notificationChannel.postMessage({ type: 'NOTIFICATION_SHOWN', baseURL: currentBaseURL });
							}
						});
					}
				}
			}, 100);
		},
		handleReceivedData(socketData) {
			let msgDetail;
			try {
				msgDetail = typeof socketData === 'string' ? JSON.parse(socketData) : socketData;
			} catch (error) {
				console.error('Failed to parse socketData:', error, socketData);
				return;
			}
			if (typeof socketData === 'string') {
				// msgDetail = JSON.parse(socketData);
				if(msgDetail.type == "update_count") {
					this.getTotalMessageCount();
				}
				
				if (msgDetail.type == 'message' && msgDetail.status != 'Failed') {
					if(msgDetail.group_id == null || msgDetail.group_id == '') {
						if(msgDetail.receiver_user_id == localStorage.getItem('userId')) {
							msgDetail.message = decryptMessage(msgDetail.message)
							if(this.isChatModelShow == true || this.isShowChatScreen == true) {
								if(msgDetail.sender_user_id == this.currentUserIdVal) {
										// this.updateStatus(msgDetail.id)
										msgDetail.status = 'Received'
										msgDetail.isSent = false;
										
										// Removing objects with isDivider
										Object.keys(this.messages).forEach((key) => {
											this.messages[key] = this.messages[key].filter(item => !item.isDivider);
										});

										this.updateMessage(msgDetail);
										this.updateStatus(msgDetail.id);
										this.scrollToLastMessage();
										this.getRecentMsgUsers();
								}
							}
							if(this.isChatModelShow == false || this.isShowChatScreen == false){
								// this.$filters.moshaToast(msgDetail.sender_name + " : " + msgDetail.message, "success");
								this.showNotification(msgDetail.sender_name, msgDetail.message);
								this.getTotalMessageCount();
								this.getRecentMsgUsers();
							}
						}
					} else {
						if(this.currentUsersGroups.includes(Number(msgDetail.group_id))) {
							msgDetail.message = decryptMessage(msgDetail.message)
							if(msgDetail.sender_user_id != localStorage.getItem('userId')) {
								if((this.isChatModelShow == true && msgDetail.group_id == this.currentGroupIdVal)) {
									if(this.isShowChatScreen == true) {
										this.updateStatus(msgDetail.id)
									}
									msgDetail.status = 'Received'
									msgDetail.isSent = false
									if (!this.senderColors[msgDetail.sender_user_id]) {
										this.senderColors[msgDetail.sender_user_id] = this.getRandomColorCode();
									}
									msgDetail.color_code = this.senderColors[msgDetail.sender_user_id];
									this.updateMessage(msgDetail);
									const div = document.getElementsByClassName("unread-messages-div")[0];
									if (div) {
										div.remove();
									}
									this.getRecentMsgUsers();
									this.scrollToLastMessage();
								} 
								if(this.isChatModelShow == false || this.isShowChatScreen == false) {
									// this.$filters.moshaToast(msgDetail.sender_name + " : " + msgDetail.message, "success");
									this.showNotification(msgDetail.group_name, msgDetail.message);
									this.getTotalMessageCount();
									this.getRecentMsgUsers();
								}
							}
						}
					}
				}

				if(msgDetail.type == 'update_group_name' || msgDetail.type == 'create_new_group') {
					this.getRecentMsgUsers();
					this.getTotalMessageCount();
				}

				if(msgDetail.type == 'update_group_members') {
					if(msgDetail.operation == 'on-remove-member' || msgDetail.operation == 'on-add-member') {
						this.isMemberExistOnGroup();
					}
				}
			}
		},
		updateStatus(msgId) {
			const headers = {
			'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
			'Content-Type': 'application/json',
			};
			axios.post("chat/update-message-status", { id: msgId, status: 'R' }, headers)
				.then((response) => {
					if(response.status == 200) {
						this.totalMessageCount = 0;
					}
				}).catch(error => {
                    if(error.response.status === 402){
                        window.open(error.response.data.redirect_url, '_blank');
                    }
                    this.$filters.moshaToast(error.message, "error")
                    this.$store.state.loader = false;
                });
		},
		getRecentMsgUsers(){
			axios.post("chat/recent-message-users",{  chatType:'user' })
				.then((response) => {
					if (response.status == 200) {
						this.recentMsgUsersData = [];
						const tempUserData = [];
						let newResponse = response.data.data;
						// this.recentMsgUsersData=response.data.data;
						$.each(newResponse, function(key, msgDetail) {
							msgDetail.message = decryptMessage(msgDetail.message)
							tempUserData.push(msgDetail)
						});
						this.recentMsgUsersData=tempUserData;
						if(this.isShowChatScreen) {
							// this.updateStatus(response.data.data[0].id);
							this.totalMessageCount = 0;
						}
						this.sendMsgToSocket({'type': 'update_count'});
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
			}).catch((error) => this.handleApiError(error));
		},
		isMemberExistOnGroup(){
			const userId = `${localStorage.getItem("userId")}`;

			if(this.currentGroupIdVal && this.isShowChatScreen) {
				axios.post("chat/group/get-members",{  chat_type:'group', group_id: this.currentGroupIdVal, current_member_id: userId})
					.then((response) => {
						if (response.status == 200) {
							const isMemberExist = response.data.data.is_member_exist;
							if(!isMemberExist) {
								this.isShowChatScreen = false;
								this.isShowNewMsgScreen = false;
								this.chatMessageUser = null;
								this.messageCount = 0;
								this.message = [];
								this.currentUserName = '';
								this.currentGroupName = '';
								this.recentMsgUsersData = [];
								this.selectedUsers = [];
								// this.fetchGroupMessages(userId, this.currentGroupIdVal);
								this.currentGroupIdVal = null;
								this.getTotalMessageCount();
								this.getRecentMsgUsers();
							}
						} else {
							this.$filters.moshaToast(response.data.message, "error");
						}
				}).catch((error) => this.handleApiError(error));

			} else {
				this.getTotalMessageCount();
				this.getRecentMsgUsers();
			}
		},
		sendMsgToSocket(newMsg)  {
			try {
				newMsg.timeStamp = Date.now(),
				this.$socket.send(newMsg);
			
			}catch (error) {
				this.$filters.moshaToast('WebSocket send error:'+error, "error");
			}
        },
		triggerFileInput() {
			this.$refs.fileInput.click();
		},
		uploadDocument(event) {
			const added_files = Array.from(event.target.files); // Convert the FileList to an array
			const validFiles = [];

			added_files.forEach((file) => {
				if (this.validateFile(file)) {
				validFiles.push(file); // Add valid files to the validFiles array
				} else {
				this.$filters.moshaToast(`File ${file.name} not valid pdf or image file.`, "error");
				}
			});

			// if (validFiles.length > 0) {
				// this.file = validFiles; // Update the files array with only valid files
				this.file.push(...validFiles); 
			// } else {
				// this.file = [];
				this.$refs.fileInput.value = null;
			// }
		},
		validateFile(file) {
          // Perform file validation here, e.g., check file type and size
          const allowedTypes = ['application/pdf', 'image/png','image/jpg', 'image/jpeg'];
        //   const maxFileSize = 2 * 1024 * 1024; // 5 MB

          if (allowedTypes.includes(file.type)) {
            return true; // File is valid
          } else {
            return false; // File is invalid
          }
        },
		toggleDiv() {
			if (this.isVisible) {
				this.msgNotificationClass= ''
				this.msgNotificationRevertClass='rotate-in-down-left'
			} else {
				this.msgNotificationRevertClass= ''
				this.msgNotificationClass= 'rotate-in-up-left'				
			}

			this.isVisible = !this.isVisible;
			setTimeout( function () {
				this.isShowRightNotiCnt = !this.isShowRightNotiCnt
				this.isShowLeftNotiCnt = !this.isShowLeftNotiCnt
			}, 100 );

		},
		getUsers() {
			// if (this.searchQuery.length > 0) {
				const headers = {
					'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
					'Content-Type': 'application/json', 
				};

				let selectedUsersIDs = [];

				this.selectedUsers.forEach((user) => {
					if(user.user_id) {
						selectedUsersIDs.push(user.user_id);
					} else {
						selectedUsersIDs.push(user.id);
					}
				});

				axios.post("chat/get-users-list", 
					{name: this.searchQuery,searched_users: selectedUsersIDs},
					{ headers: headers }
				).then((response) => {
					if (response.data.status === 200) {
						this.searchUsers = response.data.data;
					}
				}).catch((error) => {
					console.error("Error fetching messages:", error);
				});
			// }
		},
		selectSearchedUsers(userData) {
			if(this.selectedUsers.length > 0 && this.currentGroupIdVal) {
				this.updateGroupMembers = true;
			}
			this.selectedUsers.push(userData);
			this.searchQuery = '';
			this.searchUsers = [];
		},
		deselectUser(userData) {
			if(this.selectedUsers.length > 0) {
				this.updateGroupMembers = true;
			}
			this.selectedUsers = this.selectedUsers.filter(u => (u.id ?? u.user_id) !== (userData.id ?? userData.user_id));
			this.searchQuery = '';
			this.searchUsers = [];
		},
		activeSearchedUser() {
			this.isEditing = false;
			if(this.updateGroupMembers) {
				this.isShowChatScreen = true;
				this.isShowNewMsgScreen = false;
				this.newMessage = null;
				this.message = [];
				// this.currentUserName = '';
				// this.currentGroupName = '';
			} else {
				this.currentGroupIdVal = null;
				this.isShowNewMsgScreen = false;
				this.currentUserName = '';
				this.currentUserIdVal = null;
			}
			if(this.selectedUsers.length == 1) {
				this.currentGroupIdVal = null;
				this.currentGroupName = '';
				
				this.chatType = 'user';
				let user = this.selectedUsers[0];
				this.currentUserIdVal = user.id;
				this.currentUserName = user.full_name;
				this.fetchMessages(this.currentUserIdVal);
				this.scrollToLastMessage();
				this.isShowChatScreen = true;
			} else {
				if(this.updateGroupMembers == true) {
					let method = 'update';

					const selectedUsersIDs = [];

					this.selectedUsers.forEach((user) => {
						if (user.user_id) {
							selectedUsersIDs.push(user.user_id);
						} else if (user.id) {
							selectedUsersIDs.push(user.id);
						}
					});

					const headers = {
						'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
						'Content-Type': 'application/json', // Adjust this as needed for your API
					};

					axios.post("chat/group/save", 
						{
							group_name: this.currentGroupName,
							users: selectedUsersIDs,
							id: this.currentGroupIdVal,
							method: method
						},
						{ headers: headers }  // Pass headers as a separate configuration object
					).then((response) => {
						if (response.data.status === 200) {
							// open chat window with group name
							
							// this.messages = [];
							// this.currentGroupName = '';
							// this.currentGroupIdVal = null;
							// this.currentUserIdVal = response.data.data.current_user_id;
							// this.currentUsersGroups = [];
							// this.scrollToLastMessage();
							// this.fetchGroupMessages(0, this.currentGroupIdVal);
							this.isShowChatScreen = true;
							this.updateGroupMembers = false;
							this.isShowNewMsgScreen = false;
							this.isEditing = false;
							const isRemovedAnyMember = response.data.data.is_removed_any_member || false;
							const isAddAnyMember = response.data.data.is_add_any_member || false;
							if(isRemovedAnyMember) {
								this.sendMsgToSocket({'type': 'update_group_members', 'operation': 'on-remove-member'});
								this.scrollToLastMessage();
							} else if(isAddAnyMember) {
								this.sendMsgToSocket({'type': 'update_group_members', 'operation': 'on-add-member'});
								this.scrollToLastMessage();
							}
							// this.sendMsgToSocket({'type': 'create_new_group'});
						}
					}).catch((error) => {
						console.error("Error fetching messages:", error);  // Add error handling
					});
				} else {
					this.isShowNewMsgScreen = false;
					let groupName = '';
					let method = 'create';
					let selectedUsersIDs = [];
	
					this.selectedUsers.forEach((user, index) => {
						if (user.user_id) {
							selectedUsersIDs.push(user.user_id);
						} else if (user.id) {
							selectedUsersIDs.push(user.id);
						}
	
						if (index === 1 && this.selectedUsers.length > 2) {
							groupName += user.full_name + ', ...';
						} else if (index < 2) {
							groupName += user.full_name + ', ';
						}
					});
	
					// Remove the trailing comma and space
					groupName = groupName.slice(0, -2);
	
					// Limit the group name to 22 characters and add ellipsis if it's longer
					if (groupName.length > 27) {
						groupName = groupName.substring(0, 27) + '...';
					}
	
					if(this.updateGroupMembers == true) {
						method = 'update';
					}
					
					const headers = {
						'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
						'Content-Type': 'application/json', // Adjust this as needed for your API
					};
	
					axios.post("chat/group/save", 
						{
							group_name: groupName,
							users: selectedUsersIDs,
							id: this.currentGroupIdVal,
							method: method
						},
						{ headers: headers }  // Pass headers as a separate configuration object
					).then((response) => {
						if (response.data.status === 200) {
							// open chat window with group name
	
							const isRemovedAnyMember = response.data.data.is_removed_any_member || false;
							const isAddAnyMember = response.data.data.is_add_any_member || false;
							this.isEditing = false;
							if(isRemovedAnyMember) {
								this.isShowChatScreen = true;
								
								this.sendMsgToSocket({'type': 'update_group_members', 'operation': 'on-remove-member'});
								this.scrollToLastMessage();
							} else if(isAddAnyMember) {
								this.isShowChatScreen = true;
								this.isShowNewMsgScreen = false;
								this.sendMsgToSocket({'type': 'update_group_members', 'operation': 'on-add-member'});
								this.scrollToLastMessage();
							} else {
								this.currentGroupIdVal = response.data.data.id;
								this.currentUserIdVal = response.data.data.current_user_id;
								this.currentGroupName = groupName;
								this.messages = [];
								this.currentUsersGroups.push(this.currentGroupIdVal);
								this.fetchGroupMessages(0, this.currentGroupIdVal);
								this.scrollToLastMessage();
								this.isShowChatScreen = true;
								this.sendMsgToSocket({'type': 'create_new_group'});
							}
						}
					}).catch((error) => {
						console.error("Error fetching messages:", error);  // Add error handling
					});
				}
			}
		},
		getTotalMessageCount() {
			const headers = {
				'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
				'Content-Type': 'application/json', // Adjust this as needed for your API
			};
			this.totalMessageCount = 0;

			axios.post("chat/count", 
				{},
				{ headers: headers }  // Pass headers as a separate configuration object
			).then((response) => {
				if (response.data.status === 200) {
					this.totalMessageCount = response.data.data.count;
					this.$store.dispatch("updateTotalMessageCount", this.totalMessageCount);
					this.currentUsersGroups = response.data.data.groups;
				}
			}).catch((error) => {
				if(error.response.status === 402){
					console.log("Redirect payment page")
				}
				console.error("Error fetching messages:", error);  // Add error handling
			});
		},
		focusOnEdit() {
			this.isEditing = true;
			this.$nextTick(() => {
				this.$refs.groupName.focus();
			});
		},
		downloadFile(file_name) {
			const headers = {
				'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
				'Content-Type': 'application/json',
			};
			axios({
				url: "chat/download",
				method: 'POST',
				headers: headers,
				responseType: 'blob',
				data: {
					file_name: file_name,
				},
			})
			.then((response) => {
				const blob = new Blob([response.data], { type: response.headers['content-type'] });
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', file_name);
				document.body.appendChild(link);
				link.click();
				link.remove();
			})
			.catch((error) => {
				console.error("Error downloading the file:", error);
			});
		},
		triggerMove() {
			this.hasMoved = true;  // Triggers the move of the notification count
		},
		handleFadeStart(){
			// Ensure that this only executes once
			if (!this.hasMoved) {
				this.triggerMove(); // Move to the second element after the fade transition completes
			}
			// this.msgNotificationClass= ''
			// this.msgNotificationRevertClass=''
		},
		handleFadeComplete() {
			// Ensure that this only executes once
			if (!this.hasMoved) {
				this.triggerMove(); // Move to the second element after the fade transition completes
			}
			// this.msgNotificationRevertClass= 'rotate-in-up-left'
			// this.msgNotificationClass= ''
		},
		handleTransitionEnd() {
			if (!this.hasMoved) {
				this.triggerMove();
			}
		},
		beforeUnmount() {
			this.$socket.onClose();
		},
		getTimeZone() {
			let timeZone = localStorage.getItem('appTimeZone');
			if (!timeZone) {
				try {
					const headers = {
						'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
						'Content-Type': 'application/json', // Adjust this as needed for your API
					};
					axios.post("auth/get-timezone", {}, { headers: headers })
					.then((response) => {
						if (response.status === 200 && response.data.data) {
							const responseData = response.data.data;
							localStorage.setItem("appTimeZone", responseData.app_time_zone);
							console.log('TimeZone set to:', responseData.app_time_zone);
						}
					}).catch((error) => {
						console.error("Error fetching messages:", error);  // Add error handling
					});
				} catch (error) {
					console.error('Error fetching timezone from server:', error);
				}
			} else {
				console.log('App TimeZone already set:', timeZone);
			}
		},

	},
}
</script>
<style scoped>
/* Transition for fading in and out */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;transition: opacity 0.5s ease;
}


.fade-enter {
  opacity: 0;
  transform: translateX(20px); 
}
.fade-enter-active {
	transition: transform 4s, opacity 0.5s 3.5s;
}
.fade-enter-to {
  /* transform: translateX(20px);  */
  opacity: 1;
}

/* Leaving (when the element is being removed from the DOM) */
.fade-leave {
  /* transform: translateX(20px);  */
  opacity: 1;
}
.fade-leave-active {
	transition: transform 4s, opacity 0.5s 3.5s;
}
.fade-leave-to {
  opacity: 0;
  transform: translateX(-20px); 
}

</style>