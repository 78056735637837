import { createRouter, createWebHashHistory } from "vue-router";
// import Calendar from '../views/appointment/Home.vue'
import store from "@/store";

const guest = (to, from, next) => {
	if (!localStorage.getItem("authToken")) {
		return next();
	} else {
		return next("/");
	}
};

const auth = (to, from, next) => {
	if (localStorage.getItem("authToken")) {
		return next();
	} else {
		return next("/login");
	}
};

const routes = [
	{
		path: "/login",
		name: "Login",
		beforeEnter: guest,
		component: () => import('../views/Login.vue'),
		meta: { title: 'Login' }
	},
	{
		path: "/sign-up",
		name: "Sign Up",
		beforeEnter: guest,
		component: () => import('../views/SignUp.vue'),
		meta: { title: 'SignUp' }
	},
	// {
	// 	path: "/contact-us",
	// 	name: "Contact US",
	// 	beforeEnter: guest,
	// 	component: () => import('../views/ContactUs.vue'),
	// 	meta: { title: 'Contact Us' }
	// },
	{
		path: "/Unauthorized",
		name: "Unauthorized",
		component: () => import('../views/404.vue'),
		meta: { title: 'Unauthorized' }
	},
	{
		path: '/',
		name: 'Calendar',
		// component: Calendar,
		component: () => import('../views/appointment/Home.vue'),
		beforeEnter: auth,
		meta: { title: 'Appointment' }
	},
	{
		path: "/patient/query-tool",
		name: "PatientQueryTool",
		component: () => import('../views/patient/QueryTool.vue'),
		meta: { title: 'Patient Query Tool', headerTitle: 'PATIENT Query Tool' },
	},
	{
		path: "/patient",
		name: "Patient",
		component: () => import('../views/patient/Home.vue'),
		beforeEnter: auth,
		meta: {
			headerTitle: 'MASTER RECORD',
		},
		children: [
			{
				path: "create",
				name: "PatientCreate",
				meta: {
					title: 'Patient Create',
					headerTitle: 'CREATING MASTER RECORD',
				}
			},
			{
				path: "chart/:patient_id",
				name: "PatientChart",
				meta: {
					title: 'Master Record',
					headerTitle: 'MASTER RECORD',
				}
			},
			{
				path: "appointment-history/:patient_id",
				name: "PatientAppointmentHistory",
				meta: {
					title: 'Master Record',
					headerTitle: 'MASTER RECORD',
				}
			},
			{
				path: "edit/:patient_id",
				name: "PatientEdit",
				meta: { 
					title: 'Master Record',
					headerTitle: 'MASTER RECORD' 
				},
			},
			{
				path: "e-chart",
				beforeEnter: auth,
				children: [
					{
						path: ":patient_id",
						name: "PatientEChart",
						meta: { title: 'E-Chart', headerTitle: 'E-Chart' },
					},
					{
						path: ":patient_id/allergies/list",
						name: "PatientEChartAllergies",
						meta: { title: 'Allergies', headerTitle: 'ALLERGIES' },
					},
					{
						path: ":patient_id/consultation/consult-list",
						name: "PatientEChartConsultList",
						meta: { title: 'Consultation', headerTitle: 'CONSULTATION', module: "consultation" },
					},
					{
						path: ":patient_id/consultation/consult-add",
						name: "PatientEChartConsultAdd",
						meta: { title: 'Consultation', headerTitle: 'Add Consultation' },
					},
					{
						path: ":patient_id/consultation/retrieve/:id",
						name: "PatientEChartConsultEdit",
						meta: { title: 'Consultation', headerTitle: 'Consultation' },
					},
					{
						path: ":patient_id/consultation/specialist-list",
						name: "PatientEChartSpecialistsList",
						meta: { title: 'Consultation', headerTitle: 'Consultation' },
					},
					{
						path: ":patient_id/medication/prescription-list",
						name: "PatientEChartPrescriptionList",
						meta: { title: 'Medication', headerTitle: 'MEDICATIONS' },
					},
					{
						path: ":patient_id/medication/drug-list",
						name: "PatientEChartDrugList",
						meta: { title: 'Medication', headerTitle: 'MEDICATIONS' },
					},
					{
						path: ":patient_id/medication/preferred-pharmacy",
						name: "PatientEChartPreferredPharmacy",
						meta: { title: 'Medication', headerTitle: 'MEDICATIONS' },
					},
					{
						path: ":patient_id/medication/prescription-add",
						name: "PatientEChartPrescriptionAdd",
						meta: { title: 'Medication', headerTitle: 'Add Prescription' },
					},
					{
						path: ":patient_id/medication/prescription-view/:id",
						name: "PatientEChartPrescriptionView",
						meta: { title: 'Medication', headerTitle: 'VIEW PRESCRIPTION' },
					},
					{
						path: ":patient_id/preventions/immunization-list",
						name: "PatientEChartImmunizationList",
						meta: { title: 'Preventions', headerTitle: 'PREVENTIONS' },
					},
					{
						path: ":patient_id/preventions/screening-list",
						name: "PatientEChartScreeningList",
						meta: { title: 'Preventions', headerTitle: 'PREVENTIONS' },
					},
					{
						path: ":patient_id/files/overview",
						name: "PatientEChartFilesOverview",
						meta: { title: 'Files', headerTitle: 'Files' },
					},
					{
						path: ":patient_id/files/create",
						name: "PatientEChartFilesCreate",
						meta: { title: 'Files', headerTitle: false },
					},
					{
						path: ":patient_id/files/retrieve/:id",
						name: "PatientEChartFilesEdit",
						meta: { title: 'Files', headerTitle: false },
					},
					{
						path: ":patient_id/files/delete-files",
						name: "PatientEChartFilesDelete",
						meta: { title: 'Files', headerTitle: 'Files' },
					},
					{
						path: ":patient_id/imaging/list",
						name: "patientImagingList",
						meta: {  title: 'Imaging', headerTitle: 'Imaging', module: "imaging" },
					},
					{
						path: ":patient_id/labs/list",
						name: "patientLabsList",
						meta: { title: 'Labs', headerTitle: 'Labs', module: "lab" },
					},
					{
						path: ":patient_id/:module/template/edit/:template_id",
						name: "editModuleTemplate",
						meta: { title: 'Template', headerTitle: false, module: "lab" },
					},
					{
						path: ":patient_id/:module/edit/:id",
						name: "editModule",
						meta: { title: 'Template', headerTitle: false, module: "lab" },
					},
					{
						path: ":patient_id/template/:module/list",
						name: "patientTemplateList",
						meta: { title: 'Template', headerTitle: 'Template' },
					},
					{
						path: ":patient_id/measurement/list",
						name: "patientMeasurementList",
						meta: { title: 'Measurement', headerTitle: 'Measurement', module: "MeasurementList" },
					},
					{
						path: ":patient_id/measurement/group/list",
						name: "patientMeasurementGroupList",
						meta: { title: 'Measurement', headerTitle: 'Measurement', module: "MeasurementGroupList" },
					},
					{
						path: ":patient_id/tasks/active-task-list",
						name: "PatientEChartActiveTaskList",
						meta: { title: 'Tasks', headerTitle: 'TASKS' },
					},
					{
						path: ":patient_id/tasks/other-task-list",
						name: "PatientEChartCompleteDeleteTaskList",
						meta: { title: 'Tasks', headerTitle: 'TASKS' },
					},
				]
			},
			{
				path: "enrollment-history/:patient_id",
				name: "PatientEnrollmentHistory",
				meta: {
					title: 'Master Record',
					headerTitle: 'MASTER RECORD',
				}
			},
		]
	},
	{
		path: "/fax",
		name: "Inbox",
		component: () => import('../views/inbox/List.vue'),
		beforeEnter: [auth],
		meta: { ids: [store.state.permission.inbox_inbox_crud_all, store.state.permission.inbox_inbox_crud, store.state.permission.inbox_inbox_read], headerTitle: 'Fax', title: 'Faxes Received'},
		children: [
			{
				path: "deleted",
				name: "InboxDeletedList",
				beforeEnter: [auth],
				meta: { ids: [store.state.permission.inbox_inbox_crud_all], headerTitle: 'Fax', title: 'Faxes Deleted' },
			},
			{
				path: "sent-list",
				name: "FaxesSent",
				beforeEnter: [auth],
				meta: { ids: [store.state.permission.inbox_inbox_crud_all], headerTitle: 'Fax', title: 'Faxes Sent' },
			},
			{
				path: "send-a-fax",
				name: "SentAFax",
				beforeEnter: [auth],
				meta: { ids: [store.state.permission.inbox_inbox_crud_all], headerTitle: 'Fax', title: 'Send A Fax' },
			},
			{
				path: "retrieve/:id",
				name: "InboxEdit",
				beforeEnter: [auth],
				meta: { ids: [store.state.permission.inbox_inbox_crud_all], headerTitle: 'Fax', title: 'Fax' },
			}
		]
	},
	{
		path: "/task",
		name: "Task",
		component: () => import('../views/task/Home.vue'),
		beforeEnter: [auth],
		meta: { ids: [store.state.permission.task_task_crud_all, store.state.permission.task_task_crud, store.state.permission.task_task_read], headerTitle: 'TASKS', title: 'Tasks' },
		children: [
			{
				path: "active-task-list",
				name: "AllPatientActiveTaskList",
				beforeEnter: [auth],
				meta: { ids: [store.state.permission.task_task_crud_all, store.state.permission.task_task_crud, store.state.permission.task_task_read], headerTitle: 'TASKS', title: 'Tasks' },
			},
			{
				path: "complete-delete-list",
				name: "AllPatientCompleteDeleteTaskList",
				beforeEnter: [auth],
				meta: { ids: [store.state.permission.task_task_crud_all, store.state.permission.task_task_crud, store.state.permission.task_task_read], headerTitle: 'TASKS', title: 'Tasks' },
			}
		]
	},
	{
		path: "/all-labs",
		name: "AllLabList",
		component: () => import('../views/labs/Home.vue'),
		beforeEnter: [auth],
		meta: { ids: [store.state.permission.task_task_crud_all, store.state.permission.task_task_crud, store.state.permission.task_task_read], headerTitle: 'Labs', title: 'Labs', module: "lab" },
	},
	{
		path: "/all-imaging",
		name: "AllImagingList",
		component: () => import('../views/imaging/Home.vue'),
		beforeEnter: [auth],
		meta: { ids: [store.state.permission.task_task_crud_all, store.state.permission.task_task_crud, store.state.permission.task_task_read], headerTitle: 'Imaging', title: 'Imaging', module: "imaging" },
	},
	{
		path: "/all-consultation",
		name: "AllConsultationList",
		component: () => import('../views/consultation/Home.vue'),
		beforeEnter: [auth],
		meta: { ids: [store.state.permission.task_task_crud_all, store.state.permission.task_task_crud, store.state.permission.task_task_read], headerTitle: 'CONSULTATION', title: 'Consultation' },
	},
	{
		path: "/all-specialist",
		name: "AllSpecialistList",
		component: () => import('../views/consultation/Home.vue'),
		beforeEnter: [auth],
		meta: { ids: [store.state.permission.task_task_crud_all, store.state.permission.task_task_crud, store.state.permission.task_task_read], headerTitle: 'CONSULTATION', title: 'Consultation' },
	},
	{
		path: "/administration",
		component: () => import('../views/administrator/Home.vue'),
		children: [
			{
				path: "account-settings",
				name: "AccountSettings",
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration', title: 'Account Settings' },
			},
			{
				path: "clinic-settings",
				name: "clinicSettings",
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration', title: 'Clinic Settings' },
			},
			{
				path: "management-settings",
				name: "managementSettings",
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration', title: 'Management Settings' },
			},
			{
				path: "billing-custom-code",
				name: "billingCustomCode",
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration', title: 'Billing Custom Code' },
			},
			{
				path: "third-party-integrations",
				name: "thirdPartyIntegrations",
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration', title: 'Third Party Integrations' },
			},
			{
				path: "hr-analysis-report",
				name: "hrAnalysisReports",
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration', title: 'HR Analysis Reports' },
			},
			{
				path: "audit-logs",
				name: "auditLogs",
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration', title: 'Audit Logs' },
			},
			{
				path: "data-management/:patient_id?",
				name: "dataManagement",
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration', title: 'Data Management' },
			},
			{
				path: "e-result-config",
				name: "eResultConfig",
				beforeEnter: [auth],
				meta: { headerTitle: 'Administration', title: 'eResult Configuration' },
			}
		]
	},
	{
		path: "/template/list",
		name: "templateMasterList",
		component: () => import('../views/template-master.vue'),
		beforeEnter: auth,
		meta: { title:"Template Master List", headerTitle: 'Template' },
	},
	{
		path: "/template/edit/:template_id?",
		name: "templateMasterEdit",
		component: () => import('../views/template-master.vue'),
		beforeEnter: auth,
		meta: { title:"Template Master Edit", headerTitle: false },
	},
	{
		path: "/patient/:patient_id/medication/prescription-pdf/:id",
		name: "PatientEChartPrescriptionPrint",
		component: () => import('../views/prescription-print.vue'),
		meta: { title:"Prescription" },
	},
	{
		path: "/invoice",
		name: "InvoiceMaster",
		component: () => import('../views/invoice/Home.vue'),
		beforeEnter: auth,
		children: [
			{
				path: "dashboard",
				name: "InvoiceMasterDashboard",
				meta: { title:"Billing", headerTitle: 'Billing' },
			},
			{
				path: "create/:patient_id?",
				name: "InvoiceMasterCreate",
				meta: { title:"Billing", headerTitle: 'Billing' },
			},
			{
				path: "list",
				name: "InvoiceList",
				meta: { title:"Billing", headerTitle: 'Billing' },
			},
			{
				path: "analysis-reports",
				name: "InvoiceAnalysisReport",
				meta: { title:"Billing", headerTitle: 'Billing' },
			},

		]
	},
	{
		path: "/files",
		name: "Files",
		component: () => import('../views/files/Home.vue'),
		beforeEnter: auth,
		children: [
			{
				path: "overview",
				name: "EChartFilesOverview",
				meta: { title:"Files", headerTitle: 'Files' },
			},
			{
				path: "retrieve/:id",
				name: "EChartFilesEdit",
				meta: { title:"Files", headerTitle: false },
			},
			{
				path: "delete-files",
				name: "EChartFilesDelete",
				meta: { title:"Files", headerTitle: 'Files' },
			},
			{
				path: "create",
				name: "EChartFilesCreate",
				meta: { title:"Files", headerTitle: false },
			},

		]
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: () => import('../views/404.vue'),
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title;
	} else {
		document.title = 'EMR';
	}

	if (to.name === "PatientEChartPrescriptionAdd" || to.name === "PatientEChartConsultAdd") {
		let userRole = localStorage.getItem('authUserRole');
		if (userRole === 'Doctor' || userRole === 'Nurse') {
			next();
		} else {
			next(false);
			alert('You do not have permission to access this page. The tab will be closed.');
		}
	} else {
		next();
	}
});

export default router;