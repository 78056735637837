import {
	helpers,
	required,
	// email,
    // maxLength,
} from "@vuelidate/validators";

const demographic_export = {
    namespaced: true,
    state: {
        defaultFormData: {
            patient_id: null, 
            export_template: 'emr_spec_5',
            export_category:{
                is_demographic: true,
                is_encounter_notes: true,
                is_files: true,
                is_measurements: true,
                is_medication: true,
                is_other_infos: true,
                is_preventions: true,
                is_screening: true
            }
        },
        form:{
            patient_id: null,
            export_template: 'emr_spec_5',
            export_category:{
                is_demographic: true,
                is_encounter_notes: true,
                is_files: true,
                is_measurements: true,
                is_medication: true,
                is_other_infos: true,
                is_preventions: true,
                is_screening: true
            }
        },
        validationRules: {
            form: {
                patient_id: {
                    required: helpers.withMessage("Please select demographic.", required),
                }
            }
        }
    }
}

const demographic_import = {
    namespaced: true,
    state: {
        defaultFormData: {

        },
        form:{

        }
    }
}

export {demographic_export, demographic_import};