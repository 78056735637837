
export function useApiErrorHandler() {

    function handleApiError(error, is_redirect = false) {
        this.$store.state.loader = false;
        const status = error.response?.status;
        const data = error.response?.data;
        console.log(error)

        if (!status) {
            this.$filters.moshaToast("An unexpected error occurred.", "error");
            return;
        }

        switch (status) {
            case 422:
                this.$filters.moshaToast(Object.values(data?.data || {}).join(", "), "error");
                break;
            case 402:
                if (is_redirect) window.open(data?.redirect_url, "_blank");
                console.log("Redirecting to payment page");
                break;
            default:
                this.$filters.moshaToast(data?.message || "An error occurred", "error");
        }
    }

    return { handleApiError };
}
