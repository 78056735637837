// src/store/index.js
import { createStore } from "vuex";
import axios from "@/scripts/axios.js";
import patient from "./modules/patient/patient.js"
import allergies from "./modules/patient/allergies.js"
import tasks from "./modules/patient/tasks.js"
import prescriptions from "./modules/patient/prescriptions.js"
import consultations from "./modules/patient/consultations.js"
import customSpecialist from "./modules/patient/customSpecialist.js"
import pharmacy from "./modules/pharmacy.js"
import { social_history, medical_history, ongoing_concerns, reminders, risk_factors, family_history, prevention, patient_document, encounter_note,  screening, lab, imaging, disease_reg} from "./modules/patient/echart.js";
import group from "./modules/chat/group.js"
import chat from "./modules/chat/chat.js"
import {fax, sendFax} from "./modules/admin/fax.js"
import user from "./modules/admin/user.js"
import { user_site_details, user_stannp, ring_central } from "./modules/admin/system.js";
import permission from "./modules/permission.js"
import billing from "./modules/billing.js";
import {demographic_export} from "./modules/admin/datamanage.js";

export default createStore({
	state: {
		// Your state properties
		component: '',
		userInfo: null,
		patientId: null,
		loader: false,
		socketData: null,
		patientHeaderData: [],
		totalMessageCount: 0,
		appointmentProviderCustomClass: '', // Default value
	},
	mutations: {
		// Your mutations
		setSocketData(state, newData) {
			state.socketData = newData;
		},
		setAuthData(state, newData) {
			state.userInfo = newData;
		},
		setPatientHeaderData(state, newData) {
			state.patientHeaderData = newData;
		},
		setTotalMessageCount(state, count) {
			state.totalMessageCount = count;
		},
		setCustomClass(state, className) {
			console.log('Mutation triggered with:', className);
			state.appointmentProviderCustomClass = className;
		},
	},
	actions: {
		updateSocketData({ commit }, newData) {
			commit('setSocketData', newData);
		},
		updateAuthData({ commit }, newData) {
			commit('setAuthData', newData);
		},
		updatePatientHeaderData({ commit }, newData) {
			commit('setPatientHeaderData', newData);
		},
		updateTotalMessageCount({ commit }, count) {
			commit("setTotalMessageCount", count);
		},
		// Your actions
		async getDataTableContents(context, data) {
			try {
				var result;
				context.state.loader = true;
				if (data.method == "post") {
					result = await axios.post(
						context.state[data.dataType].listUrl,
						data.queryStr
					);
				} else {
					result = await axios.get(
						`${context.state[data.dataType].listUrl}${data.queryStr}`
					);
				}
				if (result.data.status === 200) {
					context.state.loader = true;
					if (result.data.data.data) {
						context.state[data.dataType].list = result.data.data.data;
					} else {
						context.state[data.dataType].list = result.data.data;
					}
					// set pagination data
					if (result.data.data.current_page) {
						context.state[data.dataType].pagination.to = result.data.data.to;
						context.state[data.dataType].pagination.from =
							result.data.data.from;
						context.state[data.dataType].pagination.total =
							result.data.data.total;
						context.state[data.dataType].pagination.last_page =
							result.data.data.last_page;
						context.state[data.dataType].pagination.current_page =
							result.data.data.current_page;
						// create pagination page nativation arr
						context.state[data.dataType].pagination.pageNavArr = [];
						for (let i = 1; i <= result.data.data.last_page; i++) {
							context.state[data.dataType].pagination.pageNavArr.push(i);
						}
					}
				} else {
					return "false";
				}
				context.state.loader = false;
			} catch (err) {
				if (err) {
					console.log(err)
					return "false";
				}
			}
		},
		updateCustomClass({ commit }, className) {
			console.log('Action triggered with:', className);
			commit('setCustomClass', className);
		},
	},
	getters: {
		// Your getters
		getSocketData: (state) => state.socketData,
		getAuthData: (state) => state.userInfo,
	},
	modules: {
		patient,
		social_history,
		medical_history,
		ongoing_concerns,
		reminders,
		risk_factors,
		family_history,
		group,
		chat,
		allergies,
		prescriptions,
		consultations,
		customSpecialist,
		prevention,
		patient_document,
		pharmacy,
		encounter_note,
		fax,
		sendFax,
		user,
		permission,
		tasks,
		screening,
		lab,
		imaging,
		disease_reg,
		billing,
		user_site_details,
		user_stannp,
		ring_central,
		demographic_export
	},
	filter: {
        status: '',
	},
});
