<template>
  <div
    v-auto-scrollbar
    class="wrapper"
    :class="$store.state.appointmentProviderCustomClass"
  >
    <div
      v-show="$store.state.loader"
      class="page-loader"
    >
      <img src="images/loader.svg" alt="loader">
      <span class="load_txt">Loading ...</span>
    </div>
    <router-view :key="$route.fullPath" />
    <ChatWidget v-if="isChatWidgetShow" />
  </div>
</template>

<script>
import ChatWidget from "./components/chat/chatWidget.vue";
import { useSessionTimeout } from "@/composables/useSessionTimeout";
console.log("useSessionTimeout:", useSessionTimeout); // ✅ Check if function is imported

export default {	
	name: 'App',
	components: {
		ChatWidget,
	},
	setup: () => {
		useSessionTimeout(); // Call the composable inside setup()
		return {}; // Return an empty object if nothing needs to be exposed
	},
	computed: {
		isLogin() {
			return localStorage.getItem("authToken") ? true : false;
		},
		isChatWidgetShow() {
			if (this.isLogin) {
				const myArray = ['Chat','BillingReceiptPrint'];
				if (myArray.includes(this.$route.name)) {
					return false;
				} else {
					return true;
				}
			}
			return false;
		},
	},
	mounted() {
		this.$socket.setMessageCallback(this.handleReceivedData);
	},
	methods:{
		handleReceivedData(socketData) {
			this.$store.dispatch('updateSocketData', socketData);
		},
	}
}
</script>
<style>

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
/* @import "../node_modules/@syncfusion/ej2-navigations/styles/material.css"; */
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css";
</style>