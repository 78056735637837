import Swal from "sweetalert2";
import { onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import axios from "@/scripts/axios.js";

export function useSessionTimeout() {
  const router = useRouter();
  const timeoutDuration = 0.5 * 60 * 1000; // 15 minutes
  let timeoutId = null;
  let logoutTimerId = null; // 🔹 Auto logout timer

  const isUserLoggedIn = () => !!localStorage.getItem("authToken");

  const resetTimer = () => {
    if (!isUserLoggedIn()) return;

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      showLogoutWarning();
    }, timeoutDuration);
  };

  const showLogoutWarning = () => {
    if (!isUserLoggedIn()) return;

    Swal.fire({
      title: "Session Expiring!",
      text: "You will be logged out due to inactivity. Do you want to stay logged in?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Logout",
      cancelButtonText: "Stay Logged In",
      customClass: {
        popup: "my-swal-popup session-expire-popup",
        title: "my-swal-title",
        confirmButton: "comman_brdr_btn swal2_cancel_button me-0",
        cancelButton: "comman_btn permission_remove_btn ml-20 confirm_bg",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        logoutUser();
      } else {
        requestSecurityCode();
      }
    });

    // Auto logout if no response in 1 minute
    logoutTimerId = setTimeout(() => {
      console.log("No response, logging out automatically...");
      logoutUser();
    }, 60 * 1000); // 1 minute
  };

  const requestSecurityCode = () => {
    Swal.fire({
      title: "Security Code",
      input: "password",
      inputPlaceholder: "Enter your Security Code",
      inputAttributes: {
        autocapitalize: "off",
        autocomplete: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Logout",
      cancelButtonText: "Verify",
      customClass: {
        popup: "my-swal-popup session-expire-popup",
        title: "my-swal-title",
        confirmButton: "comman_brdr_btn swal2_cancel_button me-0",
        cancelButton: "comman_btn permission_remove_btn ml-20 confirm_bg",
        validationMessage: "text-red", // Apply Tailwind's text-red class
        input: "my-swal-custom-input-error",
      },
      preConfirm: () => {
        console.log("User chose to log out.");
        logoutUser(); // Log the user out immediately on Confirm
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked "Verify" (previously Confirm)
        console.log("User chose to verify security code.");
        const securityCode = Swal.getInput().value; // Get input value
        axios
          .post("/user/verify-security-code", { securityCode })
          .then((response) => {
            if (response.status == 200) {
              console.log("Security code correct. User stays logged in.");
              localStorage.setItem("stayLoggedIn", Date.now());
              clearTimeout(logoutTimerId);
              resetTimer();
            } else {
              Swal.fire("Error", "Invalid Security Code", "error");
            }
          })
          .catch((error) => {
            Swal.fire("Error", error.response?.data?.message || "An error occurred", "error");
          });
      } else {
        console.log("Security code incorrect or canceled. Logging out.");
        logoutUser();
      }
    });
  };

  const logoutUser = () => {
    axios.post("auth/logout").then(() => {
      localStorage.setItem("userLoggedOut", Date.now());
      localStorage.clear();
      localStorage.removeItem("authToken");
      setTimeout(() => {
        location.reload();
      }, 100);
    });
    router.push("/login");
  };

  const handleStorageChange = (event) => {
    if (event.key === "userLoggedOut") {
      if (!isUserLoggedIn()) return;

      console.log("Detected logout from another tab, logging out...");
      logoutUser();
    }
    if (event.key === "stayLoggedIn") {
      console.log("Detected 'Stay Logged In' from another tab, closing modal...");
      Swal.fire().close();
      clearTimeout(logoutTimerId); // 🔹 Clear auto logout timeout
      resetTimer();
    }
  };

  onMounted(() => {
    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("keydown", resetTimer);
    window.addEventListener("storage", handleStorageChange); // 🔹 Listen for events from other tabs
    resetTimer();
  });

  onUnmounted(() => {
    document.removeEventListener("mousemove", resetTimer);
    document.removeEventListener("keydown", resetTimer);
    window.removeEventListener("storage", handleStorageChange);
    clearTimeout(timeoutId);
    clearTimeout(logoutTimerId);
  });

  return {
    resetTimer,
  };
}